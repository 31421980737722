import React, { Suspense } from 'react';
import { CameraControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';

import ApartmentModel from '../../components/ApartmentModel/ApartmentModel';

function ApartmentScene() {
  return (
    <Suspense>
      <Canvas>
        <ApartmentModel />
        <directionalLight intensity={1.5} position={[-2, 2, 2]} />
        <CameraControls />
      </Canvas>
    </Suspense>
  );
}

export default ApartmentScene;
