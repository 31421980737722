import React, { useEffect } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';

import type { MeshStandardMaterial } from 'three';

function ApartmentModel() {
  const data = useGLTF('/models/Room_v1_0.glb');
  const dataImage = useTexture('/textures/FinalColor.jpg');
  const dataAlphaImage = useTexture('/textures/FinalAlpha.jpg');

  useEffect(() => {
    (data.materials[''] as MeshStandardMaterial).map = dataImage;
    (data.materials[''] as MeshStandardMaterial).alphaMap = dataAlphaImage;
  }, [data?.materials, dataImage, dataAlphaImage]);

  return <primitive object={data.scene} />;
}

export default ApartmentModel;
