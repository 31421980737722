import ApartmentScene from './pages/ApartmentScene/ApartmentScene';

function App() {
  return (
    <div className="appWrapper">
      <ApartmentScene />
    </div>
  );
}

export default App;
